import { CarProfile } from './carProfile'

export enum UserType {
  HOST = 'host',
  RENTER = 'renter',
}

export enum UserStatus {
  active = 'active',
  inactive = 'inactive',
  verifying = 'verifying',
  incomplete = 'incomplete',
}

export interface NotificationSettings {
  push_notifications: boolean
  message_notifications: boolean
  promotions_announcements: boolean
}

export enum UserPaper {
  DRIVER_LICENSE = 'driver_license',
  PASSPORT_OR_ID = 'passport_or_id',
}

export interface User {
  id: string
  email: null | string
  email_verified: boolean
  phone: string
  first_name: null | string
  last_name: null | string
  date_of_birth: null | string
  country: null | string
  city: null | string
  languages: string[]
  image: null | string
  driver_license: null | string
  driver_license_issue_date: null | string
  driver_license_verified: boolean
  driver_license_verified_at: string
  passport_or_id: null | string
  passport_or_id_verified: boolean
  passport_or_id_verified_at: string
  toc_accepted: boolean
  pp_accepted: boolean
  status: UserStatus
  commercial_host: boolean
  commercial_admin_fee: number
  misconduct_counter: number
  createdAt: string
  updatedAt: string
  bank_account: null | string
  payment_cards: PaymentCard[]
  is_user_poa: null | boolean
  is_admin_poa: null | boolean
  start_date_poa: null | string
  end_date_poa: null | string
  power_of_attorney_image: string | null
  driver_license_valid_date: null | string
  driver_license_serial_number: null | string
  is_rent: null | boolean; 
}

export interface PaymentCard {
  brand: string
  card_uid: string
  created_at: string
  default: boolean
  id: string
  masked_pan: string
  updated_at: string
  user_id: string
}
export interface UserWithCarsProfiles extends User {
  car_profiles: CarProfile[]
}

export interface UserWithRelations extends UserWithCarsProfiles {
  rating?: Record<string, never>
}

export type UpdateableUserParams = Partial<
  Pick<
    User,
    | 'misconduct_counter'
    | 'first_name'
    | 'last_name'
    | 'date_of_birth'
    | 'country'
    | 'city'
    | 'bank_account'
    | 'is_user_poa'
    | 'is_admin_poa'
    | 'power_of_attorney_image'
    | 'driver_license_valid_date'
    | 'driver_license_serial_number'
    | 'is_rent'
  >
>

export enum SexId {
  Male = 'Male',
  Female = 'Female',
  Company = 'Company',
}

export type UserInsurancePolicy = {
  first_name: string
  last_name: string
  father_name: string

  address: string
  company_name: string
  location: string
  phone: string
  voen: string
  role?: string

  date_of_birth: string
  sex_id: SexId
  pin_code: string
}

export type UpdatableUserInsurancePolicyParams = UserInsurancePolicy

export type UserInsuranceUpdatableKeys = keyof UpdatableUserInsurancePolicyParams

export type UserUpdateableKeys = keyof UpdateableUserParams
