
import { markRaw, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

export default {
  name: 'LayoutHandler',
  setup() {
    const route = useRoute()

    const layout = ref()

    const getLayout = async (lyt: unknown) => {
      const c = await import(`@/layouts/${lyt}`)
      return c.default
    }

    watch(
      () => route.meta,
      async meta => {
        try {
          layout.value = markRaw(await getLayout(meta.layout))
        } catch (e) {
          console.warn('%c No layout for the route -> used Empty.\n', e)
          layout.value = markRaw(await getLayout('Empty'))
        }
      }
    )

    return { layout }
  },
}
