import axios from 'axios'

import { lsGet, LsGlobalKeys } from '@/helpers/local-storage'

import { Session, useAuthStore } from '@/store/auth'
import { parseErrors } from '@/helpers/error-parsers'

const API_HOST = process.env.VUE_APP_API_HOST

if (!API_HOST) {
  console.warn('[API] process.env.VUE_APP_API_HOST is missed')
}

const baseURL = API_HOST
  ? `${API_HOST}/admin`
  : 'https://app.rentbutik.com/api/admin'
// : 'https://rental-car-api-test-mi4xrzz6sa-ey.a.run.app/api/admin'

const client = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': 'en',
  },
})

client.interceptors.response.use(
  response => response,
  e => {
    if (parseErrors(e).errorStatus === 401) {
      const store = useAuthStore()
      store.signOut()
    }

    return Promise.reject(e)
  }
)

export const getStoredSession = (): Session | null => {
  const session = lsGet(LsGlobalKeys.AUTH)

  return session ? (JSON.parse(session) as Session) : null
}

export const setToken = (token: string) => {
  client.defaults.headers.common.Authorization = 'Bearer ' + token
}

export const removeToken = () => {
  delete client.defaults.headers.common.Authorization
}

export default client
