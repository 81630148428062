import { Admin } from '@/models/admin'

import { getStoredSession } from '@/api'
import { CommonModule, ModuleError } from '@/models/store'
import { getInitialCommonModuleState } from '@/helpers/store-state'

export interface Session {
  user: Admin
  token: string
  scopes: string[]
}

export interface State {
  session: Session | null
  signInModule: CommonModule<ModuleError<'email' | 'password'>>
}

export const getInitialAuthState = (): State => ({
  session: getStoredSession(),
  signInModule: getInitialCommonModuleState(),
})
