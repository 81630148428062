import { Router } from 'vue-router'
import isArray from 'lodash/isArray'

import { useAuthStore } from '@/store/auth'

import { routeNames } from '@/router/route-names'
import { showToast } from '@/helpers/messages'

type Guard = Parameters<Router['beforeEach']>[0]

const rbacGuard: Guard = to => {
  const authStore = useAuthStore()

  const userScopes = authStore.scopes
  const isAuthenticated = authStore.isAuthenticated

  const routeScopes = to.meta.scopes as undefined | string[]

  // Skip if route doesn't hav rbac rules
  if (!isAuthenticated || !isArray(routeScopes) || !routeScopes.length) {
    return true
  }

  // Otherwise compare user scopes with route scopes
  if (routeScopes.every(s => userScopes.includes(s))) {
    return true
  } else {
    showToast({
      type: 'error',
      message: `You don't have enough rights to access this page.`,
    })

    return { name: routeNames.dashboard, replace: true }
  }
}

export default rbacGuard
