import {
  LocationQuery,
  LocationQueryValue,
  LocationQueryValueRaw,
} from 'vue-router'

import isNil from 'lodash/isNil'
import pickBy from 'lodash/pickBy'
import mapValues from 'lodash/mapValues'
import isBoolean from 'lodash/isBoolean'
import isArray from 'lodash/isArray'
import toNumber from 'lodash/toNumber'
import isString from 'lodash/isString'

import { Primitive } from '@/models/common'
import { PaginationParams } from '@/models/api'

export const toLocationQuery = (
  params: Record<string, Primitive | Primitive[]>
): LocationQuery => {
  const notFalsy = pickBy(params, value =>
    isArray(value)
      ? value.length && value.some(arrValue => !isNil(arrValue))
      : !isNil(value)
  )

  return mapValues(notFalsy, value =>
    isArray(value)
      ? value.filter(arrValue => !isNil(arrValue)).map(String)
      : String(value)
  )
}

export const primitiveToQueryValue = (
  param: Primitive
): LocationQueryValueRaw => {
  return isBoolean(param) ? String(param) : param
}

export function normalizePaginationParams(
  query: LocationQuery
): PaginationParams {
  const {
    page: qPage,
    perPage: qPerPage,
    sortBy: qSortBy,
    sortDirection: qSortDirection,
  } = query

  const pageNumber = Number(qPage)
  const perPageNumber = Number(qPerPage)

  const page = Number.isInteger(pageNumber) ? pageNumber : 1
  const perPage = Number.isInteger(perPageNumber) ? perPageNumber : 10

  const sortDirection = qSortDirection === 'asc' ? 'asc' : 'desc'

  const params: PaginationParams = {
    page,
    perPage,
    sortDirection,
  }

  if (isString(qSortBy)) params.sortBy = qSortBy

  return params
}

export const castPagination = (query: LocationQuery): PaginationParams => {
  return normalizePaginationParams(query)
}

export const castString = (
  value: LocationQueryValue | LocationQueryValue[]
): string | undefined => {
  if (isArray(value) || isNil(value)) return

  return String(value)
}

export const castStrings = (
  query: LocationQuery,
  keys: string[]
): Record<string, string | undefined> => {
  return keys.reduce<Record<string, string | undefined>>((acc, key) => {
    acc[key] = castString(query[key])
    return acc
  }, {})
}

export const castBoolean = (
  value: LocationQueryValue | LocationQueryValue[]
): boolean | undefined => {
  if (isArray(value) || !['true', 'false'].includes(value as string)) return

  return Boolean(value)
}

export const castBooleans = (
  query: LocationQuery,
  keys: string[]
): Record<string, boolean | undefined> => {
  return keys.reduce<Record<string, boolean | undefined>>((acc, key) => {
    acc[key] = castBoolean(query[key])
    return acc
  }, {})
}

export const castNumber = (
  value: LocationQueryValue | LocationQueryValue[]
): number | undefined => {
  if (isArray(value) || isNil(value)) return

  const number = toNumber(value)

  return isNaN(number) ? undefined : number
}

export const castStringsArray = (
  value: LocationQueryValue | LocationQueryValue[]
): string[] | undefined => {
  if (isArray(value)) return value.map(val => String(val))

  return isString(value) ? [value] : undefined
}
