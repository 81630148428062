import get from 'lodash/get'

import { ValidationError } from '@/models/api'
import { ValidationErrors } from '@/models/store'

/**
 * Parses errors into the simplified object.
 *
 * @param errors An array of errors received from the backend
 * @returns {Object} Parsed into the object errors to simplify future use.
 */
export function parseValidationErrors<FieldTypes extends string = string>(
  errors: ValidationError<FieldTypes>[]
): ValidationErrors<FieldTypes> {
  return errors.reduce<ValidationErrors<FieldTypes>>((acc, current) => {
    acc[current.field] = current.message
    return acc
  }, {})
}

export interface ParseErrorsOutput<FieldTypes extends string> {
  error: string
  errorStatus: number
  errorCode: string
  validationErrors?: ValidationErrors<FieldTypes>
  validationErrorsRaw?: ValidationError[]
}

/**
 * There are basically 2 types of errors:
 *
 *   `validationErrors` - An object that contains validation errors by fields.
 *   Usually used to display errors under inputs, user entered data.
 *
 *   `error` - Common error with overall text that we just display inside a toast if needed.
 *
 * @param error A catched error
 * @returns {Object} Parsed errors
 */
export function parseErrors<FieldTypes extends string = string>(
  error: unknown
): ParseErrorsOutput<FieldTypes> {
  const parsed: ParseErrorsOutput<FieldTypes> = {
    errorStatus: get(error, 'response.status', 0),
    errorCode: get(error, 'response.data.code', ''),
    error: get(
      error,
      'response.data.message',
      get(error, 'message', 'Unknown error')
    ),
  }

  const validationErrors: ValidationError[] | undefined = get(
    error,
    'response.data.errors'
  )
  if (validationErrors && validationErrors.length) {
    parsed.validationErrorsRaw = validationErrors
    parsed.validationErrors = parseValidationErrors(validationErrors)
  }

  return parsed
}
