import { ElNotification } from 'element-plus'

import { parseErrors } from './error-parsers'
import { commonCase } from './strings'

export const showToast = (options: {
  type: 'success' | 'error'
  message: string
}) => {
  const { type, message } = options

  const duration = type === 'error' ? 10000 : 6000

  ElNotification({ type, message, duration })
}

export const getUpdatesErrorMsg = (keys: string[], e: unknown) => {
  const { error, validationErrorsRaw } = parseErrors(e)

  const keysString = keys.map(key => commonCase(key, true)).join(', ')

  return `An error while updating ${keysString}: "${validationErrorsRaw?.[0]
    .message || error}"`
}

export const getUpdatesSuccessMsg = (keys: string[]) => {
  const keysString = keys.map(key => commonCase(key, true)).join(', ')

  return `${keysString} value successfully updated.`
}
